import { Model } from '@app/Model.js';
import { User } from '@app/User.ts';
import { notification, br } from '@utils';
import { html, render } from 'lit';
import { globals } from '@globals';
import { Storage } from './Storage.js';
import { Log } from '@modules/Log.ts';

export const socket = {
	instance: null,
	errorcount: 0,
	auth: null,
	restart: null,
	lastQueues: null,
	storage: new Storage('socket', 'local'),
	get seenPushes() {
		return this.storage.get('seenPushes') || [];
	},
	get indicator() {
		return window.appElement.renderRoot.querySelector('mail-indicator');
	},
	initAndAuthorize(auth) {
		clearTimeout(this.restart);
		if (!auth) return;
		this.auth = auth;
		if (!this.instance) this.instance = new WebSocket(globals.yoummdaySocket);
		this.instance.onopen = () => {
			if ('Notification' in window && Notification.permission === 'default')
				Notification.requestPermission();
			this.errorcount = 0;
			this.instance.send(`auth=${this.auth}`);
		};
		this.instance.onclose = () => {
			this.instance = null;
			clearTimeout(this.restart);
			this.errorcount += 1;
			if (this.auth) {
				this.restart = setTimeout(
					(self) => {
						self.initAndAuthorize(self.auth);
					},
					this.errorcount * 5000,
					this,
				);
			}
		};
		this.instance.onmessage = (e) => {
			let { data } = e;

			try {
				data = JSON.parse(data);
				const { unreadmessages } = data || {};
				if (Number.isInteger(unreadmessages)) {
					this.storage.set('msgs', unreadmessages);
					this.indicator?.setAttribute('counter', unreadmessages);
				}
			} catch (err) {
				Log.error(err);
				return;
			}
			if (data.error) {
				if (data.error === 'auth') {
					this.auth = null;
				}
				if (data.error === 'timeout') {
					this.errorcount += 1;
				}
				return;
			}
			if (!data.type || !this[`${data.type}Event`]) return;
			this[`${data.type}Event`](data);
		};
		this.instance.onerror = () => {
			this.errorcount += 1;
		};
	},

	callEvent(data) {
		if (this.storage.get('currentCallId') === data.callId) return;
		this.storage.set('currentCallId', data.callId);

		notification(
			window.T.message.event.call_new,
			`${data.user.company} | ${data.module.title}`,
			window.L10n.language,
		);

		window.appElement.dispatchEvent(
			new CustomEvent('showOverlay', {
				detail: {
					name: 'call',
					data: { call: data },
				},
			}),
		);
	},

	callstateEvent({ calling, reason = '', connectionPhone = 1 }) {
		if (
			!calling &&
			reason &&
			window.L10n.translations[window.L10n.language].alert.error.telephony[
				reason
			]
		) {
			Log.log('reason called failed', reason);
			if (connectionPhone === 0) {
				/*
				 * in this case, backend set the user to connectionPhone: 0, so we do the same
				 * mutate user object (see User.ts->set user())
				 */
				User.user = { connectionPhone };
				window.Dialog.showDialog({
					confirmButtonText: window.T.cta.close,
					noDismissButton: true,
					type: 'danger',
					titleText: window.T.term.error,
					html: html`
						${window.T.alert.error.telephony.NO_ANSWER}
						<br />
						<small>[${reason}]</small>
					`,
				});
				window.appElement.requestUpdate();
			} else if (reason !== 'NO_ANSWER') {
				window.Dialog.showDialog({
					confirmButtonText: window.T.cta.close,
					noDismissButton: true,
					type: 'danger',
					titleText: window.T.term.error,
					html:
						reason === 'UNALLOCATED_NUMBER' &&
						User.user.connectionType !== 'webphone'
							? window.T.alert.error.telephony.UNALLOCATED_NUMBER_sipphone
							: html`
									${window.T.alert.error.telephony[reason]}
									<br />
									<small>[${reason}]</small>
								`,
				});
			}
		}
	},

	pushEvent(data) {
		const messages = data.msgs.filter(
			(push) =>
				!push.answer && // not answered yet
				!this.seenPushes.includes(push.id), // not yet seen
		);
		if (!messages.length) return;

		const addToSeenPushes = (id) => {
			this.seenPushes.push(id);
			this.storage.push('seenPushes', id);
		};
		const singlePush = (push) => {
			const container = document.createElement('div');
			const react = async (e) => {
				const btn = e.currentTarget;
				e.preventDefault();
				const { pns = [] } = await Model.data.notificationanswer({
					answer: btn.dataset.answer,
					pnId: push.id,
				});
				const unanswered = pns.filter((psh) => !psh.answer);
				if (!unanswered.length) {
					container.dispatchEvent(
						new CustomEvent('destroyToast', { bubbles: true }),
					);
				} else {
					btn.closest('div').remove();
				}
				addToSeenPushes(push.id);
			};
			render(
				html`
					<div>
						<div style="display:flex; align-items:center;">
							<div>
								<user-thumb
									.user=${push.user || { ymdItself: true }}
									size="40"
								></user-thumb>
							</div>
							<div>
								<h5 style="font-size:1.1em; margin:0;">${push.title}</h5>
							</div>
						</div>
						<p>${br(push.text)}</p>
						${push.user && globals.gui === 'talent'
							? html`
									<p>
										<sl-button
											@click=${react}
											variant="danger"
											data-answer="no"
										>
											<iconify-icon
												icon="mdi-close-circle"
												slot="prefix"
											></iconify-icon>
											${window.T.cta.decline}
										</sl-button>
										<sl-button
											@click=${react}
											variant="success"
											data-answer="yes"
										>
											<iconify-icon
												icon="mdi-check-circle"
												slot="prefix"
											></iconify-icon>
											${window.T.cta.agree}
										</sl-button>
									</p>
								`
							: ''}
					</div>
				`,
				container,
			);
			return container;
		};
		notification(window.T.message.event.pushmsg, '', window.L10n.language);
		messages.forEach((message) => {
			const container = singlePush(message);
			window.appElement.dispatchEvent(
				new CustomEvent('showToast', {
					detail: {
						html: container,
						onDismissed: () => {
							messages.forEach((push) => {
								addToSeenPushes(push.id);
							});
						},
					},
				}),
			);
		});
	},

	callqueueEvent(data) {
		if (
			globals.gui === 'talent' &&
			window.appElement.route.template &&
			window.appElement.route.as === 'home'
		) {
			this.lastQueues = data;
			window.appElement.route.template.rawData = { fifos: data };
			window.appElement.route.template.render();
		}
	},

	kill() {
		this.errorcount = 0;
		this.auth = null;
		if (this.instance) this.instance.close();
	},
};
