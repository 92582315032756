import { Model } from '@app/Model.js';
import { randomString } from '@utils';
import { globals } from '@globals';
import { Log } from '@modules/Log.ts';

export const RocketChatSocketService = {
	initAndAuthorize() {
		const ws = globals.rocketChatSocket;
		const auth = Model.Storage.get('rocketchat').token;
		clearTimeout(this.restart);
		if (!auth) return;
		this.auth = auth;
		this.initialSubscriptionId = '';
		this.realtimeMessages = [];
		if (!this.instance) this.instance = new WebSocket(ws);
		this.onOpenInstance();
		this.onCloseInstance();
		this.onMessageInstance();
		this.onError();
	},

	onOpenInstance() {
		const connectConfig = {
			msg: 'connect',
			version: '1',
			support: ['1'],
		};

		this.instance.onopen = () => {
			if ('Notification' in window && Notification.permission === 'default')
				Notification.requestPermission(() => {});
			this.errorcount = 0;
			this.instance.send(JSON.stringify(connectConfig));
			this.loginRequest(Model.Storage.get('rocketchat').token);
		};
	},

	onCloseInstance() {
		this.instance.onclose = () => {
			this.instance = null;
			clearTimeout(this.restart);
			this.errorcount += 1;
			this.restart = setTimeout(
				(self) => {
					self.initAndAuthorize(self.auth);
				},
				this.errorcount * 5000, // ???
				this,
			);
		};
	},

	async handleMessage(data) {
		const { msg } = data;
		if (msg === 'ping') this.instance.send(JSON.stringify({ msg: 'pong' }));

		if (msg === 'connected') {
			await this.getSubscriptions();
			this.streamNotifyUser();
		}

		if (msg === 'changed' && data.collection === 'stream-notify-user') {
			this.updateRealtimeMessages(data.fields.args);
			this.updateUnreadMessagesCount();
		}

		if (msg === 'result' && data.id === this.initialSubscriptionId) {
			this.getRealtimeMessages(data.result);
			this.updateUnreadMessagesCount();
		}
	},

	onMessageInstance() {
		this.instance.onmessage = async (e) => {
			let { data } = e;
			try {
				data = JSON.parse(data);
				await this.handleMessage(data);
			} catch (err) {
				Log.error(err);
				return;
			}
			if (data.error) {
				if (data.error === 'auth') {
					this.auth = null;
				}
				if (data.error === 'timeout') {
					this.errorcount += 1;
				}
				return;
			}
			if (!data.type || !this[`${data.type}Event`]) return;
			this[`${data.type}Event`](data);
		};
	},

	onError() {
		this.instance.onerror = () => {
			this.errorcount += 1;
		};
	},

	loginRequest(auth) {
		const loginConfig = {
			msg: 'method',
			method: 'login',
			id: randomString(),
			params: [{ resume: auth }],
		};

		this.instance.send(JSON.stringify(loginConfig));
	},

	getSubscriptions() {
		const hashString = randomString();
		const subscriptionsConfig = {
			msg: 'method',
			method: 'subscriptions/get',
			id: hashString,
			params: [],
		};
		this.initialSubscriptionId = hashString;

		this.instance.send(JSON.stringify(subscriptionsConfig));
	},

	streamNotifyUser() {
		const streamNotifyUserConf = {
			msg: 'sub',
			id: randomString(),
			name: 'stream-notify-user',
			params: [
				`${Model.Storage.get('rocketchat').chatUserId}/subscriptions-changed`,
				false,
			],
		};
		this.instance.send(JSON.stringify(streamNotifyUserConf));
	},

	getRealtimeMessages(data) {
		this.realtimeMessages = data
			.filter((res) => res.unread > 0 && !res.archived && res.open === true)
			.map(({ rid, unread }) => ({ rid, unread }));

		const rcStorage = Model.Storage.get('rocketchat');
		Model.Storage.set(
			'rocketchat',
			Object.assign(rcStorage, {
				realtimeMessages: this.realtimeMessages,
			}),
		);
	},

	updateUnreadMessagesCount() {
		let totalUnread = 0;
		Model.Storage.get('rocketchat')?.realtimeMessages?.forEach((res) => {
			totalUnread += res.unread;
		});

		const getChatElement = async () =>
			await window.appElement.shadowRoot.querySelector('chat-launcher');

		getChatElement().then((elem) => {
			if (elem) elem.unreadCount = totalUnread;
		});
	},

	updateRealtimeMessages(data) {
		const update = { rid: data[1].rid, unread: data[1].unread };
		this.realtimeMessages = this.realtimeMessages.find(
			(obj) => obj.rid === update.rid,
		)
			? this.realtimeMessages.map((obj) =>
					obj.rid === update.rid ? update : obj,
				)
			: [].concat(this.realtimeMessages, [update]);

		const rcStorage = Model.Storage.get('rocketchat') || {};
		Model.Storage.set(
			'rocketchat',
			Object.assign(rcStorage, {
				realtimeMessages: this.realtimeMessages,
			}),
		);
	},
};
