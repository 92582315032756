import type { Token } from 'markdown-it';
import markdownit from 'markdown-it';

const htmlConverter = markdownit({
	html: false,
	linkify: false,
	breaks: true,
});

const defaultRender =
	htmlConverter.renderer.rules.link_open ||
	function (tokens, idx, options, _env, self) {
		return self.renderToken(tokens, idx, options);
	};

const replaceTokenAttribute = (
	token: Token,
	attrName: string,
	attrValue: string,
) => {
	const targetAttrIndex = token.attrIndex(attrName);
	if (targetAttrIndex < 0) {
		token.attrPush([attrName, attrValue]);
	} else if (token.attrs) {
		token.attrs[targetAttrIndex][1] = attrValue;
	}

	return token;
};
// eslint-disable-next-line camelcase
htmlConverter.renderer.rules.link_open = function (
	tokens,
	idx,
	options,
	env,
	self,
) {
	let linkToken = tokens[idx];
	linkToken = replaceTokenAttribute(linkToken, 'target', '_blank');
	linkToken = replaceTokenAttribute(linkToken, 'ref', 'noopener noreferrer');
	tokens[idx] = linkToken;
	return defaultRender(tokens, idx, options, env, self);
};

export { htmlConverter };
