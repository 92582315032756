import { Log } from '@modules/Log.ts';
export class Storage {
	constructor(partition = '', storageType = 'session') {
		this.partition = `YMD${partition ? `.${partition}` : ''}`;
		this.storageType = window[`${storageType}Storage`];
		this.local = null; // this will contain another Storage instance
		if (
			!this.storageType ||
			this.storageType.toString() !== '[object Storage]'
		) {
			throw new Error('invalid storage type');
		}
	}

	get(key) {
		const stored = this.storageType.getItem(`${this.partition}.${key}`);
		if (!stored) return null;
		try {
			return JSON.parse(stored);
		} catch (error) {
			Log.error?.(error);
			return stored;
		}
	}

	set(key, value) {
		try {
			this.storageType.setItem(
				`${this.partition}.${key}`,
				JSON.stringify(value),
			);
		} catch (err) {
			// storing failed, most certainly the object is too big to store
			Log.error(err);
		}
		return value;
	}

	has(key) {
		return this.keys().includes(key);
	}

	update(key, updateObj) {
		return this.set(key, {
			...(this.get(key) || {}),
			...updateObj,
		});
	}

	push(key, arrayEl) {
		const arr = this.get(key) || [];
		arr.push(arrayEl);
		return this.set(key, arr);
	}

	keys() {
		return Object.keys(this.storageType)
			.filter((key) => key.startsWith(this.partition))
			.map((key) => key.substring(this.partition.length + 1));
	}

	remove(key) {
		this.storageType.removeItem(`${this.partition}.${key}`);
	}

	removeAll(exceptKeys = []) {
		this.keys()
			.filter((key) => !exceptKeys.includes(key))
			.forEach((key) => {
				this.remove(key);
			});
	}
}
