export const queryStringToObject = (query = '') =>
	[...new URLSearchParams(query)].reduce((accumulator, [k, v]) => {
		accumulator[encodeURIComponent(k)] = encodeURIComponent(v);
		return accumulator;
	}, {});

export const convertStringToNumericOrBoolOrNot = (string) => {
	if (string === 'true' || string === 'false') return string === 'true';
	return !isNaN(parseFloat(string)) && isFinite(string)
		? parseFloat(string)
		: string;
};

export const serializationOfIterator = (iterator, convertType = false) =>
	Array.from(iterator)
		// transform entries map to deep structured object
		.reduce((serialized, [name, value]) => {
			// convert value to another type?
			const val = convertType
				? convertStringToNumericOrBoolOrNot(value)
				: value;
			// occurance of arrays? separation...
			const [prop, arrProp] = name.split('[]');
			// theres about to be an array of objects
			if (arrProp) {
				// create array if not yet done
				serialized[prop] = serialized[prop] || [];
				// get the last element in the array
				const prevObj = serialized[prop].length
					? serialized[prop][serialized[prop].length - 1]
					: null;
				// cleanup property names from '[' and ']'
				const deepProp = arrProp.replace(/[[\]]+/gu, '');
				// it's the first occurance, or in the last occurance is the current property already set
				if (!prevObj || prevObj[deepProp]) {
					// new element in array with current property set
					serialized[prop].push({ [deepProp]: val });
				} else {
					// add property to previous object in list
					prevObj[deepProp] = val;
				}
				// it's about to be a flat array
			} else if (name.includes('[]')) {
				if (!Array.isArray(serialized[prop])) {
					serialized[prop] = [];
				}
				// concat current value to existing list || or new list
				serialized[prop].push(val);
			} else {
				// nothing special here; add formdata row to object
				serialized[name] = val;
			}
			return serialized;
		}, {});

// { a: { b: 1 }, c: { d: 1 }, e: ['One', 'Twö'] } becomes a[b]=1&c[d]=2&e[0]=One&e[1]=Two
export const deepNestedObjectToQueryString = (
	object = {},
	isSubEncode = false,
	prefix = '',
) => {
	const parts = Object.entries(object)
		.map(([key, value]) => {
			const encodedParts = [];
			if (Array.isArray(value)) {
				value.forEach((_a, i) => {
					if (_a instanceof Object) {
						encodedParts.push(
							deepNestedObjectToQueryString(_a, true, `${prefix}${key}[${i}]`),
						);
					} else {
						encodedParts.push(
							`${prefix}${key}[${i}]=${encodeURIComponent(_a)}`,
						);
					}
				});
			} else if (value instanceof Object) {
				Object.entries(value).forEach(([innerKey, innerValue]) => {
					if (Array.isArray(innerValue)) {
						encodedParts.push(
							deepNestedObjectToQueryString(
								innerValue,
								true,
								`${prefix}[${key}][${innerKey}]`,
							),
						);
					} else {
						encodedParts.push(
							`${key}[${innerKey}]=${encodeURIComponent(innerValue)}`,
						);
					}
				});
			} else if (isSubEncode) {
				encodedParts.push(`${prefix}[${key}]=${encodeURIComponent(value)}`);
			} else if (value !== '') {
				// remove empty strings from parameters
				encodedParts.push(`${key}=${encodeURIComponent(value)}`);
			}
			return encodedParts.join('&');
		})
		.filter((v) => !!v);
	return parts.join('&');
};
