import { User } from '@app/User.ts';
import { html } from 'lit';
import { globals } from '@globals';

const initHbspt = (onFormIsReady) => {
	const formtmp = document.createElement('div');
	formtmp.setAttribute('id', 'formtmp');
	document.body.appendChild(formtmp);

	window.hbspt.forms.create({
		region: 'eu1',
		portalId: '25869763',
		formId: window.T.url.hbspt_help_form_id,
		target: '#formtmp',
		onFormReady: (form) => {
			const { elements } = form;
			elements.talent_id.value = User.user.id;
			elements.email.value = User.user.email;
			elements.lastname.value = User.user.name;
			elements['TICKET.url_form_origin'].value = window.location.href;
			elements['TICKET.plattformversion'].value = globals.appVersion;
			onFormIsReady();
		},
	});
};

const getDialog = () => html`
	<div
		id="hbspt-form-target"
		class="p-4"
	>
		<div class="d-flex justify-content-center fs-1">
			<sl-spinner></sl-spinner>
		</div>
	</div>
`;

export const openHelpForm = () => {
	const onFormIsReady = () => {
		const formtmp = document.getElementById('formtmp');
		if (formtmp) {
			window.appElement.shadowRoot
				.getElementById('hbspt-form-target')
				.replaceChildren(formtmp);
		}
	};

	window.Dialog.showDialog({
		html: getDialog(),
		noDismissButton: true,
		size: 'large',
		noConfirmButton: true,
		titleText: window.T.headline.support,
		didOpen: () => {
			if (!document.getElementById('hbspt-js')) {
				const script = document.createElement('script');
				script.id = 'hbspt-js';
				script.async = true;
				script.setAttribute('type', 'text/javascript');
				script.setAttribute('src', 'https://js-eu1.hsforms.net/forms/v2.js');
				script.addEventListener('load', () => {
					initHbspt(onFormIsReady);
				});
				document.head.appendChild(script);
			} else if (window.hbspt) {
				initHbspt(onFormIsReady);
			}
		},
	});
};
