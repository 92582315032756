export const chars = {
	lowerCase: 'abcdefghijklmnopqrstuvwxyz',
	numbers: '0123456789',
	specialChars: '@#$%^&+=',
	upperCase: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
};

export const randomString = (
	length: number = 12,
	{
		lowerCase = true,
		upperCase = true,
		numbers = true,
		specialChars = true,
	}: {
		lowerCase?: boolean;
		upperCase?: boolean;
		numbers?: boolean;
		specialChars?: boolean;
	} = {},
): string => {
	const inclusions = {
		lowerCase,
		upperCase,
		numbers,
		specialChars,
	};

	let poolOfChars = '';
	const randomStringArray: string[] = [];
	let count = length || 12;

	Object.entries(inclusions)
		.filter(([, include]) => include)
		.forEach(([type]) => {
			poolOfChars += chars[type as keyof typeof chars];
			randomStringArray.push(
				chars[type as keyof typeof chars][
					Math.floor(Math.random() * chars[type as keyof typeof chars].length)
				],
			);
			count -= 1;
		});

	randomStringArray.push(
		...[...Array(count)].map(
			() => poolOfChars[Math.floor(Math.random() * poolOfChars.length)],
		),
	);

	const randomlySortedRandomString = randomStringArray
		.sort(() => Math.random() - 0.5)
		.join('');

	return randomlySortedRandomString;
};
