const isSmoothScrollSupported =
	'scrollBehavior' in document.documentElement.style;

type scrollOptions = {
	behavior?: 'smooth' | 'instant' | 'auto';
	block?: 'center' | 'end' | 'nearest';
	inline?: 'start' | 'center' | 'end';
};

export const smoothScroll = (
	targetElement?: Element,
	additionalOptions?: scrollOptions,
): void => {
	targetElement?.scrollIntoView(
		isSmoothScrollSupported
			? {
					behavior: 'smooth',
					...(additionalOptions || {}),
				}
			: false,
	);
};
