import { customElement, property } from 'lit/decorators.js';
import { css, html, LitElement } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import { matomoTracker } from '../../app/matomoTracker.js';

interface TrackClick {
	name: string;
	options?: object;
}

@customElement('track-link')
export default class TrackLink extends LitElement {
	@property({ type: Boolean }) blank: boolean = false;

	@property({ type: String }) href?: string | null = null;

	@property({ type: Object }) track?: TrackClick | null = null;

	static styles = css`
		:host(*) {
			cursor: pointer;
		}
		a {
			text-decoration: none;
			color: inherit;
		}
	`;

	connectedCallback() {
		super.connectedCallback();
		this.addEventListener('click', this.onLinkClick);
	}

	onLinkClick() {
		if (this.track) {
			matomoTracker.track({
				name: this.track.name,
				...(this.track.options ? { options: this.track.options } : {}),
			});
		}
	}

	render() {
		return this.href
			? html`
					<a
						href=${this.href}
						target=${ifDefined(this.blank ? '_blank' : null)}
					>
						<slot></slot>
					</a>
				`
			: html`
					<slot></slot>
				`;
	}
}

declare global {
	interface HTMLElementTagNameMap {
		'track-link': TrackLink;
	}
}
