import { Model } from '@app/Model.js';
import { APIv2 } from '@API';
import { RocketChatSocketService } from './RocketChatSocketService.js';

export const RocketChatService = {
	async init() {
		const rcProperties = this.getRocketChatProperties();

		const { token, chatUserId, chatUsername } = rcProperties.token
			? rcProperties
			: (await this.getAuth()) || {};

		this.setRocketChatProperties({ token, chatUserId, chatUsername });
		await RocketChatSocketService.initAndAuthorize();
	},

	async getAuth() {
		const { data } = await APIv2.GET(`/chat/user/token`);
		return data;
	},

	setRocketChatProperties(...props) {
		const existingProps = this.getRocketChatProperties() || {};

		Model.Storage.set('rocketchat', Object.assign(existingProps, ...props));
	},

	getRocketChatProperties(prop = '') {
		const storage = Model.Storage.get('rocketchat');
		return prop ? storage?.[prop] : storage || {};
	},
};
