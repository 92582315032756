import type SlAlert from '@shoelace-style/shoelace/dist/components/alert/alert.js';

export type ShowMessageEventDetails = {
	str: string;
	variant?: SlAlert['variant'];
};

export type ShowMessageEventArguments = [
	str: ShowMessageEventDetails['str'],
	variant?: ShowMessageEventDetails['variant'],
];

const customEventDefaultConfig = {
	bubbles: true,
	composed: true,
};
const namespace = 'app-shell';

export const createAlertMessageEvent: (
	...args: ShowMessageEventArguments
) => CustomEvent<ShowMessageEventDetails> = (str, variant = 'success') =>
	new CustomEvent(`${namespace}-showMessage`, {
		detail: { str, variant },
		...customEventDefaultConfig,
	});

export const createRerenderEvent = (): CustomEvent =>
	new CustomEvent(`${namespace}-rerender`, customEventDefaultConfig);

declare global {
	interface HTMLElementEventMap {
		navigate: CustomEvent<string>;
		['app-shell-showMessage']: ReturnType<typeof createAlertMessageEvent>;
		destroyModal: CustomEvent;
		showToast: CustomEvent<object>;
		destroyToast: CustomEvent;
		showOverlay: CustomEvent<object>;
		destroyOverlay: CustomEvent;
		switchNavigationScope: CustomEvent;
		['app-shell-rerender']: ReturnType<typeof createRerenderEvent>;
	}
}
