export const internalLinkSelector =
	'a[href]:not([href="#"]):not([href^="mailto:"]):not([target])';

export const onLinkClick = (e: Event) => {
	e.preventDefault();
	const element = e.currentTarget as HTMLAnchorElement;
	const href = element.getAttribute('href');

	if (!href) return;
	const evt = new CustomEvent('navigate', {
		detail: href,
		bubbles: true,
		composed: true,
	});
	element.dispatchEvent(evt);
};
