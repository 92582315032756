import { globals } from '@globals';
import { Storage } from '../app/Storage.js';

export const Log: {
	storageInstance: Storage | null;
	storage: () => Storage;
	debug: boolean;
	log: (...args: unknown[]) => void;
	error: (err: Error) => void;
} = {
	storageInstance: null,
	storage() {
		this.storageInstance = this.storageInstance || new Storage('Log');
		return this.storageInstance;
	},
	get debug() {
		return !globals.isProduction || this.storage().get('debug') === '1';
	},

	set debug(bool) {
		const debugModeNumber = Number(bool);
		this.storage().set('debug', debugModeNumber);
	},
	log(...args) {
		if (!globals.isProduction || this.debug) {
			// eslint-disable-next-line no-console
			console.log(...args);
		}
	},
	error(err) {
		if (globals.sentryOn && typeof Sentry !== 'undefined') {
			Sentry.captureException(err);
		}
		// eslint-disable-next-line no-console
		console.error(err);
	},
};
