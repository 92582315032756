import { css } from 'lit';

export const style = css`
	aside {
		height: var(--yd-header-height);
		position: fixed;
		bottom: 0;
		z-index: 4;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 0 1rem;
		width: calc(100vw - 2rem);
		max-width: 100vw;
		background: var(--bs-gray-14);
		color: var(--yd-color-white);
	}

	aside iconify-icon {
		cursor: pointer;
	}

	@media only screen and (min-width: 768px) {
		aside.d-md-none {
			display: none;
		}
	}
`;
