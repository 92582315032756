import { Model } from '@app/Model.js';
import { User } from './User';
import { Router } from '../../router';

export const canAccessWithoutVerifiedEmail = (
	ydRoute: TalentRoute,
): boolean => {
	if (!User.emailVerified && !ydRoute.isAllowedWithoutVerifiedEmail) {
		Router.navigate(`/welcome${window.location.search}`);
		return false;
	}
	return true;
};

export const canAccessWithoutOnboarding = (ydRoute: TalentRoute): boolean => {
	if (!User.isOnboarded && !ydRoute.isAllowedWithoutOnboarding) {
		Router.navigate(`/join${window.location.search}`);
		return false;
	}
	if (
		User.isOnboarded &&
		['join', 'onboarding'].some((viewname) => viewname === ydRoute.as)
	) {
		Router.navigate(`/${window.location.search}`);
		return false;
	}
	return true;
};

export const isSalesToolAdmin = async (): Promise<boolean> => {
	const rights = await Model.data.salessuccessreportrights();
	User.user = {
		...User.user,
		isSalesToolAdmin: rights.admin,
	};
	if (User.isOnboarded && User.user.isSalesToolAdmin) {
		return rights.admin;
	}
	if (User.isOnboarded) {
		Router.navigate(`/${window.location.search}`);
		return false;
	}

	return rights.admin;
};

export const isSalesTool = async (): Promise<boolean> => {
	const rights = await Model.data.salessuccessreportrights();
	if (rights.admin) {
		User.user = {
			...User.user,
			isSalesToolAdmin: rights.admin,
		};
	}

	return true;
};
