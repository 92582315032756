import { User } from '@app/User';
import { Model } from '@app/Model.js';
import { html, LitElement, render } from 'lit';
import {
	customElement,
	property,
	query,
	queryAll,
	state,
} from 'lit/decorators.js';
import { when } from 'lit/directives/when.js';
import { classMap } from 'lit/directives/class-map.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { globals } from '@globals';
import { Log } from '@modules/Log';
import style from './style.scss?inline';
import { Router } from '../../router';
import { initDEPRECATED, onreadyDEPRECATED } from './deprecated';
import { audioControls } from '../../app/AudioPlayer.js';
import {
	onLinkClick,
	internalLinkSelector,
} from '@utils/evtHandler/internalLinkClick';
import { matomoTracker } from '../../app/matomoTracker.js';
import '../../app/talent/components/dark-footer/index.js';
import '../../app/manage/components/setup-progress/index.js';
import './custom-events';

import type Template from '../../app/Template.js';
import type { ShowMessageEventDetails } from './custom-events';
import type { DialogProperties, ToastProperties } from './types';
import type { PropertyValues } from 'lit';
import type { NavigationScope } from 'src/@types/navigationScopes';
import type SlAlert from '@shoelace-style/shoelace/dist/components/alert/alert.js';
import '../chat-launcher/chat-launcher';

const globalInit = async () => {
	try {
		matomoTracker.init();
		const { user = {}, error = '' } = await Model.data.appinit();
		const hasSession =
			(Boolean(user.id) || error !== 'auth') && error !== 'tfaloginrequired';
		User.hasSession = hasSession;

		Router.resolve();
	} catch (err) {
		Log.error(err);
	}
};

const tagName = 'app-shell';

@customElement(tagName)
export default class AppShell extends LitElement {
	@property({ type: Boolean }) isLoading: boolean = false;

	@property({ type: Object }) route: Route | TalentRoute = {
		as: '',
		uses: () => {},
	};

	@property({ type: Object }) statics: AppStatics = {
		countries: {},
		currencies: [],
		languages: {},
		defaultcode: '',
		businesses: {},
		countrycodes: [],
		euCountries: [],
		euVatIdRequired: [],
		minPrivVersion: -1,
		softskills: [],
		timezones: {},
		ustCountries: [],
		highlyDemandedLanguages: [],
	};

	@property({ type: Object }) toast!: ToastProperties | null;

	@property({ type: Object }) dialog!: DialogProperties | null;

	@property({ type: Object }) modal!: Template | null;

	@property({ type: Object }) overlay!: Template | null;

	@state() scope: NavigationScope = 'default';

	@state() snackBarMessage?: {
		msg: string;
		variant: SlAlert['variant'];
		icon: string;
	} | null;

	@query('yd-dialog') dialogElement!: LitElement;

	/*
	 *	to be deprecated: elements should become components
	 */
	@query('header') headerElement!: HTMLElement;

	@query('main:not(.overlay) > section') mainElement!: HTMLElement;

	@query('main.overlay') overlayElement!: HTMLElement;

	@query('aside') asideElement!: HTMLElement;

	@query('.modal') modalElement!: HTMLElement;

	@query('.snack-bar') snackBarElement!: SlAlert;

	@queryAll(internalLinkSelector)
	nativeLinks!: HTMLCollection;

	static styles = [style];

	static getElement() {
		return document.getElementsByTagName(tagName)?.[0];
	}

	constructor() {
		super();

		this.addEventListener(`${tagName}-rerender`, () => {
			this.requestUpdate();
		});

		this.addEventListener('navigate', ({ detail: newUrl }) => {
			Router.navigate(newUrl);
		});

		/*
		 * DEPRECATED needs to be refactored to dialog!
		 * Modal
		 */
		this.addEventListener('showModal', ({ detail }: CustomEvent) => {
			const { name, data } = detail;
			if (this.modal && this.modal.name !== name) this.modal.destroy();
			import(`@app/modal/${name}.js`).then(({ default: Modal }) => {
				this.modal = new Modal(this.modalElement, data, null, this, name);
				this.modal?.init();
			});
		});
		this.addEventListener('destroyModal', () => {
			this.modal?.destroy();
			this.modal = null;
		});

		this.addEventListener('showOverlay', ({ detail }: CustomEvent) => {
			const { name, data = {} } = detail;
			if (this.overlay && this.overlay.name !== name) this.overlay.destroy();
			import(`@app/pages/${name}.js`).then(({ default: Overlay }) => {
				this.overlay = new Overlay(this.overlayElement, null, null, this, name);
				if (this.overlay) {
					this.overlay._rawData = {
						...this.overlay?._rawData,
						...data,
					};
				}
				this.overlay?.init();
			});
		});
		this.addEventListener('destroyOverlay', () => {
			this.overlay?.destroy();
			this.overlay = null;
		});

		/*
		 *	NavigationSwitch
		 */
		this.addEventListener(
			'switchNavigationScope',
			({ detail: { scope } }: CustomEvent) => {
				this.scope = scope;
			},
		);

		this.addEventListener(`${tagName}-showMessage`, ({ detail }) => {
			if (detail.variant === 'danger') {
				this.dialog = {
					html: html`
						${unsafeHTML(detail.str)}
					`,
					noDismissButton: true,
					type: detail.variant,
					titleText: window.T.term.error,
				};
			} else {
				this.showSnackBar(detail);
			}
		});

		/*
		 *	Toast
		 */
		this.addEventListener('showToast', ({ detail }: CustomEvent) => {
			this.showToast(detail.html, detail.onDismissed, detail.variant);
		});
		this.addEventListener('destroyToast', () => {
			const alert = document.body.querySelector('sl-alert');
			alert?.hide();
		});
	}

	async connectedCallback(): Promise<void> {
		if (window.self !== window.top) return;
		super.connectedCallback();
		await initDEPRECATED(this);
		await globalInit();

		this.statics = await Model.statics();
		onreadyDEPRECATED(this);
	}

	async updated(_changedProperties: PropertyValues<this>) {
		super.updated(_changedProperties);
		if (
			// do not rerender header and aside too much
			!(_changedProperties.has('isLoading') && _changedProperties.size === 1)
		) {
			if (this.asideElement) {
				const { aside } = await import('@app/aside.js');
				render(aside(this.scope), this.asideElement);
			}
			if (this.headerElement) {
				const { header } = await import('@app/header.js');
				render(
					header(this.scope),

					this.headerElement,
				);
			}
		}

		[...this.nativeLinks].forEach((a) => {
			a.addEventListener('click', onLinkClick);
		});
	}

	showToast(
		element: HTMLElement,
		onDismissed: () => void,
		variant: SlAlert['variant'],
	) {
		const alert = document.createElement('sl-alert');
		alert.variant = variant || 'neutral';
		alert.closable = true;
		alert.append(element);

		this.append(alert);
		if (onDismissed) {
			alert.addEventListener('sl-after-hide', () => {
				onDismissed();
			});
		}
		return alert.toast();
	}

	showSnackBar({ str, variant = 'success' }: ShowMessageEventDetails) {
		if (!str) return;
		const escapeHtml = (txt: string) => {
			const div = document.createElement('div');
			div.textContent = txt;
			return div.innerHTML;
		};
		const icons: { [key: string]: string } = {
			danger: 'material-symbols:report-outline-rounded',
			primary: 'material-symbols:info-outline-rounded',
			success: 'material-symbols:check-circle-outline-rounded',
			warning: 'material-symbols:warning-outline-rounded',
		};

		this.snackBarMessage = {
			variant,
			icon: icons[variant],
			msg: escapeHtml(str),
		};
		this.snackBarElement.show();
	}

	renderDialog() {
		return html`
			<yd-dialog
				@opened=${(e: Event) => {
					if (this.dialog?.didOpen) this.dialog.didOpen(e.currentTarget);
				}}
				@dismissed=${() => {
					this.dialog?.resolve?.({ isDismissed: true });
					this.dialog = null;
				}}
				@confirmed=${(e: CustomEvent) => {
					let confirmValue = e.detail?.value || {};
					if (this.dialog?.preConfirm) {
						confirmValue = this.dialog.preConfirm(this.dialogElement);
						if (confirmValue === false) {
							return;
						}
					}
					this.dialog?.resolve?.({ isDismissed: false, value: confirmValue });
					this.dialog = null;
				}}
				.scrollable=${typeof this.dialog?.scrollable === 'undefined' ||
				this.dialog?.scrollable}
				.variant=${this.dialog?.variant}
				.noDismissButton=${this.dialog?.noDismissButton}
				.noConfirmButton=${this.dialog?.noConfirmButton}
				.noEscape=${this.dialog?.noEscape}
				.size=${this.dialog?.size}
				.type=${this.dialog?.type}
				confirmButtonText=${this.dialog?.confirmButtonText || window.T.cta.ok}
				dismissButtonText=${this.dialog?.dismissButtonText ||
				window.T.cta.cancel}
			>
				${when(
					this.dialog?.titleText,
					() => html`
						<div slot="header">${this.dialog?.titleText}</div>
					`,
				)}
				${this.dialog?.html}
			</yd-dialog>
		`;
	}

	render() {
		return html`
			<article class=${classMap({ 'has-header': !!this.route.hasHeader })}>
				${when(
					(this.route.hasAsideNavigation && User.hasSession) ||
						(this.route.hasAsideSalesToolNavigation && User.hasSession),
					() => html`
						<aside></aside>
					`,
				)}
				${when(
					User.hasSession,
					() => html`
						<main class="overlay ${classMap({ open: !!this.overlay })}"></main>
					`,
				)}

				<main>
					<slot name="hubspot-chatbot"></slot>

					${when(
						globals.gui === 'manage' &&
							User.hasSession &&
							User.company?.wantsSetupProgress === 1,
						() => html`
							<setup-progress
								.currentRoute=${Router.current?.[0]?.url}
							></setup-progress>
						`,
					)}
					${when(
						globals.gui === 'talent' && User.hasSession && !User.isOnboarded,
						() => html`
							<dark-footer
								.obStep=${User.obStep}
								.showOnboarding=${this.route.as !== 'onboarding'}
							>
								<track-link
									slot="onboarding"
									href="onboarding/${User.obStep}"
									.track=${{ name: 'ob.join.getready' }}
									class="btn btn-emphasize btn-flat ms-3"
								>
									${window.T.nav.onboarding}
								</track-link>
							</dark-footer>
						`,
					)}
					${audioControls}
					<section
						class=${classMap({
							'no-padding':
								!this.route.hasHeader && !this.route.hasAsideNavigation,
							'is-loading': this.isLoading,
						})}
					>
						<div class="loading-bar"></div>
						<slot></slot>
						<div id="page"></div>
					</section>
				</main>
				${when(
					User.hasSession && window.appElement.route.hasRocketchat,
					() => html`
						<chat-launcher
							.collisionPrevention=${User.isSelfservice}
						></chat-launcher>
					`,
				)}
			</article>

			${when(
				this.route.hasHeader && User.hasSession,
				() => html`
					<header></header>
				`,
			)}

			<div class="modal"></div>

			${when(this.dialog, () => this.renderDialog())}
			<sl-alert
				class="snack-bar"
				.variant=${this.snackBarMessage?.variant || 'success'}
				.closable=${true}
				.duration=${3000}
				@sl-after-hide=${() => (this.snackBarMessage = null)}
			>
				<iconify-icon
					icon=${this.snackBarMessage?.icon || ''}
					width="22"
					slot="icon"
				></iconify-icon>
				${this.snackBarMessage?.msg}
			</sl-alert>
		`;
	}
}

declare global {
	interface HTMLElementTagNameMap {
		'app-shell': AppShell;
	}
}
