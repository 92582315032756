import dingdong from './ring.mp3';

const ringtone = new Audio(dingdong);
ringtone.loop = true;
const reset = (e) => {
	e.currentTarget.currentTime = 0;
};
ringtone.addEventListener('pause', reset);

export { ringtone };
