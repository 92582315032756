import moment from 'moment-timezone';
import { Model } from '@app/Model.js';
import { socket } from './Socket.js';

export type DeviceType = 'mobile' | 'desktop';
type someAPIUser = APIuser | APItalent | APIcompany;

export class BaseUser {
	private _hasSession: boolean = false;

	browserNotifications: NotificationPermission | null =
		'Notification' in window ? Notification.permission : null;

	systemzone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

	async userObjectReceived(user: someAPIUser): Promise<void> {
		this.user = { ...user };
		Model.Storage.local.set('authUser', user);
		const { lang, timezone, features = [] } = user;
		if (lang) await window.L10n.setLanguage(lang);
		if (timezone) moment.tz.setDefault(timezone);
		if (features.length) Model.Storage.local.set('features', features);
	}

	async logout(): Promise<void> {
		await Model.data.logout();
		this.hasSession = false;
	}

	get user(): someAPIUser {
		return Model.Storage.local.get('authUser') || {};
	}

	set user(updatedUser: someAPIUser) {
		Model.Storage.local.set('authUser', {
			...this.user,
			...updatedUser,
		});
	}

	get hasSession(): boolean {
		return this._hasSession;
	}

	set hasSession(bool) {
		this._hasSession = bool;
		if (!this._hasSession) {
			window.Router.navigate('/login', {
				updateBrowserURL: false,
			});

			socket.kill();
			Model.cleanup();
		}
	}

	get preferredLanguage(): string {
		return this.user.lang || window.navigator.language || 'en';
	}

	get emailVerified(): boolean {
		return !!this.user.emailverified;
	}

	can(feature: string = ''): boolean {
		const { features = [] } = this.user;
		return features.includes(feature);
	}

	get ymmdTeam(): boolean {
		return (
			!!this.user.email &&
			/@(external\.|icon\.)?yoummday\.com$/u.test(this.user.email)
		);
	}

	get ymdMail(): string {
		return this.user?.ymdmail;
	}

	get device(): {
		type: DeviceType;
		hasTouchScreen: boolean;
	} {
		const type: DeviceType = window.innerWidth <= 768 ? 'mobile' : 'desktop';
		const hasTouchScreen =
			'ontouchstart' in document.documentElement &&
			navigator.maxTouchPoints > 1;
		return { type, hasTouchScreen };
	}
}
