import ModelClass from '../Model.js';

export default class ModelTalent extends ModelClass {
	onAPIpriceCheckLowError(error, path, response) {
		return this.resendAfterForceDialog(error, path, response);
	}
}

const Model = new ModelTalent();
export { Model };
