/* eslint-disable camelcase */
import { User } from '@app/User';
import { globals } from '@globals';
import { readCookieByName } from '@utils/cookies';
import { Log } from '@modules/Log';

interface ConsentLevels {
	functionality?: boolean;
	'strictly-necessary'?: boolean;
	tracking?: boolean;
	targeting?: boolean;
}

interface EventActions {
	user: 'userInteraction';
	ui: 'uiTriggered';
	page: 'trackPageView';
}

type ValueOf<T> = T[keyof T];

const matomoContainers = {
	talent: {
		prod: 'https://cdn.matomo.cloud/yoummday.matomo.cloud/container_Y2pxd8H2.js',
		staging:
			'https://cdn.matomo.cloud/yoummday.matomo.cloud/container_Y2pxd8H2_staging_0e14376186e4986bd9957152.js',
	},
	manage: {
		prod: 'https://cdn.matomo.cloud/yoummday.matomo.cloud/container_G3OYBRu0.js',
		staging:
			'https://cdn.matomo.cloud/yoummday.matomo.cloud/container_G3OYBRu0_staging_516b2b7c0134e41ce675e433.js',
	},
	onboarding: {
		prod: 'https://cdn.matomo.cloud/yoummday.matomo.cloud/container_6DYdij3W.js',
		staging:
			'https://cdn.matomo.cloud/yoummday.matomo.cloud/container_6DYdij3W_staging_ec35e463dc2ef2cd84a72b94.js',
	},
};

const getTagManagerContainerUrl = () => {
	const { prod, staging } =
		matomoContainers[globals.gui] || matomoContainers.talent;
	return globals.isProduction ? prod : staging;
};

const trackingCategories = {
	talent: {
		ob: [
			'join',
			'account',
			'onboarding',
			'mobiledocupload',
			'webcamsession',
			'welcome',
		],
		messaging: ['mailbox'],
		'Talent Main Navigation': ['tal'],
	},
	manage: {
		progress: ['cuaccount', 'balance', 'addservice', 'findtalents'],
		'Create Quality Check': ['qualityadd'],
		'Manage Quality Assurance': ['quality', 'qualityview', 'qualitycheck'],
		'Manage Monitoring Dashboard': ['monitor'],
		'Manage Platform Header': ['header'],
	},
	onboarding: {},
};

const findCategory = (val: string) => {
	const guiTrackingCategories = trackingCategories[globals.gui];

	const currentCategoryIndex = Object.values(guiTrackingCategories).findIndex(
		(viewNames) => viewNames.some((viewName) => val.startsWith(viewName)),
	);

	return Object.keys(guiTrackingCategories)[currentCategoryIndex];
};

const getTrackingCategory = (source: string) => {
	let category;

	category = findCategory(source);

	if (!category) {
		/**
		 * When a page is migrated to the new page PageBase component the template property doesn't have the "name" property and so we use the "as" property of the "route"
		 */
		const currentViewname =
			window.appElement.route.template?.name ||
			window.appElement.route.as ||
			'';

		category = findCategory(currentViewname);
	}

	return category;
};

const canTrack = (): boolean => {
	const levelCookie = readCookieByName('cookie_consent_level') || '';

	if (
		levelCookie === '' ||
		!window._paq ||
		!window._mtm ||
		!globals.isProduction // only track on prod system
	) {
		return false;
	}
	try {
		return (
			(JSON.parse(decodeURIComponent(levelCookie)) as ConsentLevels).tracking ||
			false
		);
	} catch (error: unknown) {
		Log.error(error as Error);
		return false;
	}
};

const userId = () => User.user.id || null;

const isAlreadyTracked = ({
	dataLayer,
	eventName,
}: {
	dataLayer: Window['_mtm'];
	eventName: string;
}): boolean =>
	dataLayer.some(
		(mtmEvent) =>
			'event_data' in mtmEvent && mtmEvent.event_data?.name === eventName,
	);

export const matomoTracker = {
	init() {
		window._mtm = window._mtm || [];
		const { _mtm } = window;
		_mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
		const d = document;
		const g = d.createElement('script');
		const [s] = d.getElementsByTagName('script');
		g.async = true;
		g.src = getTagManagerContainerUrl();
		s.parentNode!.insertBefore(g, s);
	},

	eventTrackForeignUser(userid = '', ...trackingParams: Array<string>) {
		if (!canTrack() || !userid) return;
		window._paq.push(['setCustomUrl', window.location.pathname]);
		window._paq.push(['setUserId', userid]);
		window._paq.push(['trackEvent', ...trackingParams]);
	},

	eventTrack(categroy?: string, action?: ValueOf<EventActions>, name?: string) {
		if (!canTrack()) return;
		window._paq.push(['setCustomUrl', window.location.pathname]);
		window._paq.push(['setUserId', userId()]);
		window._paq.push(['trackEvent', categroy, action, name]);
	},

	track({
		trigger = 'user',
		name,
		once = false,
		options = {},
	}: {
		trigger?: keyof EventActions;
		name: string;
		once?: boolean;
		options?: object;
	}) {
		const { _mtm } = window;
		if (
			!canTrack() ||
			(once && isAlreadyTracked({ dataLayer: _mtm, eventName: name }))
		)
			return;

		if (!_mtm.some((mtmEvent) => 'uid' in mtmEvent)) {
			_mtm.push({ uid: userId() });
		}

		const eventValues: EventActions = {
			user: 'userInteraction',
			ui: 'uiTriggered',
			page: 'trackPageView',
		};

		const [source] = name.split('.');

		_mtm.push({
			event: 'event-to-tag',
			event_data: {
				name,
				action: eventValues[trigger],
				category: getTrackingCategory(source),
				options: { ...options, trackOnce: once },
			},
		});
	},

	pageTrack() {
		if (!canTrack()) return;
		setTimeout(() => {
			if (window._svc && User.hasSession) {
				window._sva?.setVisitorTraits({
					user_id: 'yoummday', // this should not be a real userId!
				});
			}
			const dimensions = {
				dimension2: User.company?.productPlan,
				dimension3: User.company?.company,
			};
			window._paq.push(['setCustomUrl', window.location.pathname]);
			window._paq.push(['setUserId', userId()]);

			window._paq.push(['trackPageView', document.title, dimensions]);

			window._mtm.push({
				event: 'trackPageView',
				customUrl: window.location.pathname,
			});
		}, 100);
	},
	conversionTrack(convEvent = '') {
		if (!canTrack() || !convEvent) return;
		window._mtm.push({
			event: 'event-to-advertiser',
			'event-to-advertiser': convEvent,
		});
	},
};
