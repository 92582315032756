import moment from 'moment-timezone';

const clocks: HTMLSpanElement[] = [];
const countdowns: [HTMLSpanElement, string][] = [];

const currentClockTime = (tz: string): string => moment.tz(tz).format('dd LT');
const currentCounter = (mom: moment.Moment): string =>
	mom.isAfter() ? mom.fromNow() : '';

setInterval(() => {
	clocks.forEach((clock, i) => {
		if (!clock.isConnected) {
			clocks.splice(i, 1);
			return;
		}
		const newTime = currentClockTime(clock.dataset.timezone || 'UTC');
		if (clock.innerText !== newTime) clock.innerText = newTime;
	});
	countdowns.forEach(([countdownEl, ts], i) => {
		if (!countdownEl.isConnected) {
			countdowns.splice(i, 1);
			return;
		}
		countdownEl.innerText = currentCounter(moment(ts));
	});
}, 1000);

export const clock = (tz: string = ''): HTMLSpanElement => {
	const clockContainer = document.createElement('span');
	clockContainer.dataset.timezone =
		tz || Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
	clockContainer.innerText = currentClockTime(clockContainer.dataset.timezone);
	clocks.push(clockContainer);
	return clockContainer;
};

export const countdown = (ts: string): HTMLSpanElement => {
	const countdownContainer = document.createElement('span');
	countdownContainer.innerText = currentCounter(moment(ts));
	countdowns.push([countdownContainer, ts]);
	return countdownContainer;
};
