import { routes, Router } from '..';

export const getRouteFromMatch = (match: Match) => {
	const { route, data: paramsFromSlugs, params: paramsFromQuery } = match || {};
	const ydRoutePath = Router.root.length
		? `${route.path.substring(Router.root.length)}`
		: `/${route.path}`;
	const ydRoute: Route = routes[ydRoutePath || '/'];

	const params = Object.fromEntries(
		Object.entries(paramsFromSlugs || {}).map(([k, v]) => [
			k,
			encodeURIComponent(v),
		]),
	);
	const query = Object.fromEntries(
		Object.entries(paramsFromQuery || {}).map(([k, v]) => [
			encodeURIComponent(k),
			encodeURIComponent(v),
		]),
	);
	return {
		params,
		query,
		ydRoute,
	};
};
