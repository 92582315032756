import createClient from 'openapi-fetch';
import { deepNestedObjectToQueryString } from '@utils/forms.js';
import type { Endpoints, EndpointsWithErrorKeyInResponses } from './types';

const appToApiControllerMap = {
	manage: 'manage',
	talent: 'provider',
	onboarding: 'onboard',
	backoffice: 'admin',
};
const currentApp = import.meta.env.VITE_APP;
const baseUrls = {
	v1: `${import.meta.env.VITE_API_HOST}${appToApiControllerMap[currentApp]}/`,
	v2: `${import.meta.env.VITE_API_HOST}v2/`,
};
const baseUrl = baseUrls.v1;

export { baseUrl as apiBaseURL };
export const APIv2 = createClient<Endpoints>({
	baseUrl: baseUrls.v2,
	credentials: 'include',
});

export const API = createClient<EndpointsWithErrorKeyInResponses>({
	baseUrl,
	credentials: 'include',
	bodySerializer: deepNestedObjectToQueryString,
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
	},
});

export const UploadAPI = createClient<EndpointsWithErrorKeyInResponses>({
	baseUrl,
	bodySerializer(body) {
		const formData = new FormData();
		Object.entries(body || {}).forEach(([k, v]) => {
			if (v && (typeof v === 'string' || v instanceof Blob))
				formData.append(k, v);
		});
		return formData;
	},
	credentials: 'include',
});

export const tsFormat = 'YYYY-MM-DD HH:mm:ss';

export { ModelClass } from './Modelv2';
