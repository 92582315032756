import { globals } from '@globals';
import { User } from '@app/User';
import { serializationOfIterator } from '@utils/forms.js';
import Localisation from '../../l10n/index.js';
import { Router } from '../../router/index.js';
import type { DialogProperties } from '@components/app-shell/types';

export const initDEPRECATED = async (appElement: HTMLElement) => {
	// should/could be integrated in a <yd-form> component one day
	HTMLFormElement.prototype.serializeObject = function serializeObject(
		convertType: boolean,
	) {
		const formEntries = new FormData(this).entries();
		return serializationOfIterator(formEntries, convertType);
	};

	const L10n = new Localisation();
	window.L10n = L10n;
	await L10n.setLanguage(User.preferredLanguage, appElement);

	if (globals.gui === 'talent' && !User.device.hasTouchScreen) {
		const { TypingDNA } = await import('../../modules/dna.js');
		window.Tdna = new TypingDNA();
	}

	// backwards compatibility, should be imported where needed instead
	Object.assign(window, {
		T: L10n.txt,
		prismicContent: L10n.prismicContent,
		appElement,
		Dialog: {
			showDialog(args: DialogProperties) {
				return new Promise((resolve) => {
					window.appElement.dialog = {
						...args,
						resolve,
					};
				});
			},
		},
		Router,
	});
};

export const onreadyDEPRECATED = (appElement: HTMLElement) => {
	const { shadowRoot } = appElement;
	shadowRoot?.addEventListener('change', (e) => {
		const target = e.target as HTMLInputElement;

		if (typeof target.name === 'string' && target.name?.startsWith('tab')) {
			shadowRoot.querySelectorAll('[for^="tab"]').forEach((forTab) => {
				const activateTab = shadowRoot.querySelector(
					`#${forTab.getAttribute('for')}`,
				) as HTMLInputElement;
				if (activateTab && 'checked' in activateTab)
					forTab.classList.toggle('active', activateTab.checked);
			});
		}

		if (target.hasAttribute('data-for')) {
			const dataFor = target.getAttribute('data-for') as string;
			const checkboxes = shadowRoot.querySelectorAll(
				dataFor,
			) as NodeListOf<HTMLInputElement>;
			checkboxes.forEach((checkbox) => {
				checkbox.checked = target.checked;
				checkbox.dispatchEvent(new Event('change', { bubbles: true }));
			});
		}
	});
};
