import { html, LitElement } from 'lit';
import { style } from './style.css.js';

export default class DarkFooter extends LitElement {
	static properties = {
		obStep: {
			type: String,
		},
		showOnboarding: {
			type: Boolean,
		},
	};

	static styles = [style];

	constructor() {
		super();
		this.obStep = '';
		this.showOnboarding = false;
	}

	render() {
		return html`
			<aside class="d-md-none">
				<iconify-icon
					@click="${() =>
						window.appElement.scrollTo({ top: 0, behavior: 'smooth' })}"
					icon="ic-outline-arrow-circle-up"
					class="iconify"
					width="30"
				></iconify-icon>

				${this.showOnboarding
					? html`
							<slot name="onboarding"></slot>
						`
					: ''}
			</aside>
		`;
	}
}

customElements.define('dark-footer', DarkFooter);
