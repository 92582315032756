import { User } from '@app/User.ts';
import { Model } from '@app/Model.js';
import { Storage } from '../../../Storage.js';
import { html, LitElement } from 'lit';
import { matomoTracker } from '../../../matomoTracker.ts';
import { style } from './style.css.js';
import { createRerenderEvent } from '@components/app-shell/custom-events.ts';

export class SetupProgress extends LitElement {
	static properties = {
		steps: {
			type: Array,
			state: true,
		},
		currentRoute: {
			type: String,
		},
	};

	static styles = [style];

	constructor() {
		super();
		this.interval = null;
		this.routes = {
			account: [`cuaccount/${User.company.id}`, 'cuaccount'],
			balance: [`balance/${User.company.id}`, 'balance'],
			module: ['addservice', 'projects', 'project'],
			contract: [`findtalents/${User.company.id}`, 'findtalents'],
		};
		this.matomoEventNames = {
			account: 'Click on "Complete data"',
			balance: 'Click on "Recharge credit"',
			module: 'Click on "Configure project"',
			contract: 'Click on "Recruit talent"',
		};
		this.Storage = new Storage('setup-assistant', 'local');
	}

	async fetchSteps() {
		const { success, steps = [] } = await Model.data.progressbar({
			companyuser: User.company.id,
		});

		if (success && JSON.stringify(steps) !== JSON.stringify(this.steps)) {
			this.steps = steps;
		}
	}

	connectedCallback() {
		super.connectedCallback();

		this.fetchSteps();

		if (!this.interval) {
			this.interval = setInterval(() => this.fetchSteps(), 11 * 1000);
		}
	}

	disconnectedCallback() {
		super.disconnectedCallback();
		clearInterval(this.interval);
	}

	render() {
		let { steps = [] } = this;

		if (!steps.length) {
			return '';
		}
		const removeFeature = async (e) => {
			e.preventDefault();
			e.stopPropagation();
			const target = e.currentTarget;

			const { isDismissed } = await window.Dialog.showDialog({
				titleText: window.T.cta.hide_setup_assistant,
				html: window.T.alert.info.remove_setup_assistant,
				confirmButtonText: window.T.cta.confirm,
			});

			if (isDismissed) {
				return;
			}

			const { success, user } = await Model.data.cuaccount({
				companyuser: User.company.id,
				wantsSetupProgress: 0,
			});

			if (success) {
				User.mutateCompany(user, user.id);
				target.dispatchEvent(createRerenderEvent());
			}
		};

		steps = steps.map((step) => ({
			...step,
			label: window.T.self_service_progress[step.name],
			active: this.routes[step.name].includes(this.currentRoute.split('/')[0]),
		}));

		const doneSteps = steps.filter((step) => step.done);

		return html`
			<sl-details
				?open=${this.Storage.get('progress')}
				@sl-show=${() => {
					this.Storage.set('progress', true);
				}}
				@sl-hide=${() => {
					this.Storage.set('progress', false);
				}}
			>
				<div slot="summary">
					<div class="title">
						<span>
							<span>
								<strong>Get Started!</strong>
								${window.T.label.self_service_progress}
							</span>
							${doneSteps.length === steps.length
								? html`
										<a
											href="#"
											@click=${removeFeature}
										>
											${window.T.cta.hide_setup_assistant}
										</a>
									`
								: ''}
						</span>
						<span class="steps">${doneSteps.length} / ${steps.length}</span>
					</div>
				</div>
				<yd-progress-indicator
					.steps=${steps}
					@navigate=${({ detail }) => {
						window.Router.navigate(this.routes[detail.stepName][0]);
						matomoTracker.track({
							name: this.matomoEventNames[detail.stepName],
						});
					}}
				>
					<span slot="title">
						<strong>Get Started!</strong>
						${window.T.label.self_service_progress}
					</span>
				</yd-progress-indicator>
			</sl-details>
		`;
	}
}

customElements.define('setup-progress', SetupProgress);
