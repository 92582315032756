import { css } from 'lit';

export const style = css`
	[slot='summary'] {
		width: 100%;
	}
	.title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 1.125rem;
	}

	.steps {
		white-space: nowrap;
		margin-right: 20px;
	}
`;
