export const confirmNavigation = async (
	canLeave: (arg1: boolean) => void,
	content = window.T.alert.warning.not_saved,
	confirmButtonText = window.T.cta.not_save,
) => {
	if (!window.appElement.route.template?.confirmNavigation) {
		return canLeave(true);
	}
	const { isDismissed } = await window.Dialog.showDialog({
		html: content,
		confirmButtonText,
		type: 'warning',
		titleText: window.T.term.warning,
	});
	return canLeave(!isDismissed);
};
